import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import ApiClient from '../../Services/ApiClient.js';
import { ToastsStore } from 'react-toasts';
import { useNavigate } from 'react-router-dom';
let Country = require("country-state-city").Country;

export default function ContactForm(props) {

    const generateCaptcha = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let code = '';
        for (let i = 0; i < 6; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            code += characters.charAt(randomIndex);
        }
        return code;
    }

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const badge = props?.badge;
    const buttonText = props?.buttonText;
    const isFullSize = props?.isFullSize;
    const privacyPolicy = props?.privacyPolicy;
    const [captchaCode, setCaptchaCode] = useState(generateCaptcha());
    const Countries = Country.getAllCountries();

    const initialValues = {
        firstname: '',
        lastname: '',
        email: '',
        country_name: '',
        city: '',
        mobile: '',
        ticket_title: '',
        description: '',
        captcha_code: ''
    }

    const validationSchema = Yup.object().shape({
        firstname: Yup.string()
            .matches(/^[a-zA-Z]+$/, 'Please enter alphabetic characters only.')
            .trim()
            .required('Please enter your first name.'),
        lastname: Yup.string()
            .matches(/^[a-zA-Z]+$/, 'Please enter alphabetic characters only.')
            .trim()
            .required('Please enter your last name.'),
        email: Yup.string()
            .email('Please enter valid email address.')
            .trim()
            .required('Please enter your email address.'),
        country_name: Yup.string()
            .matches(/^(?!.*\bCountry\b).+$/, 'Please select a country.')
            .trim()
            .required('Please select a country.'),
        city: Yup.string()
            .matches(/^[a-zA-Z]*$/, 'Please enter alphabetic characters only.')
            .trim(),
        mobile: Yup.string()
            .matches(/^[0-9]{8,14}$/, 'Please enter a valid mobile number (8-14 digits).')
            .trim()
            .required('Please enter your mobile number.'),
        ticket_title: Yup.string()
            .trim()
            .required('Please enter the subject.'),
        description: Yup.string()
            .trim()
            .required('Please enter a message.'),
        captcha_code: Yup.string()
            .trim()
            .oneOf([captchaCode, null], "Please fill correct captch.")
            .required("Enter given captcha in this field."),
    })


    const captchHandler = () => {
        setCaptchaCode(generateCaptcha());
    }

    const handleSubmit = (values, { resetForm }) => {
        setCaptchaCode(generateCaptcha());
        let formData = new FormData();
        formData.append('web_operation', 'WebContactUs');
        formData.append('module_name', 'Contacts');
        formData.append('leadstatus', 'New');
        formData.append('leadsource', 'Contact us Form');
        formData.append('firstname', values?.firstname);
        formData.append('lastname', values?.lastname);
        formData.append('email', values?.email);
        formData.append('country_name', values?.country_name);
        formData.append('city', values?.city);
        formData.append('mobile', values?.mobile);
        formData.append('ticket_title', values?.ticket_title);
        formData.append('description', values?.description);

        setIsLoading(true);
        ApiClient.post('form_api.php', formData).then(res => {
            if (res.success) {
                ToastsStore.success(res?.success_message?.message);
                setIsLoading(false);
                resetForm();
            } else {
                setIsLoading(false);
                resetForm();
            }
        })
    }

    return (
        <div>
            <div className='contact-form p-3 p-md-5'>
                {badge &&
                    <div className='availability-badge mb-4'>
                        <p className='m-0'>{badge}</p>
                    </div>
                }
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, { resetForm }) => {
                        handleSubmit(values, { resetForm });
                    }}
                >
                    {({ errors, touched }) => (
                        <Form autoComplete='off'>
                            <div className='row m-0 p-0'>
                                <div className='col-md-6 px-0 pr-md-2'>
                                    <Field name='firstname' className='w-100' placeholder='First Name' />
                                    <div className='errorMsg'>
                                        <p className='m-0 text-danger pe-2'>
                                            {errors.firstname && touched.firstname ? (errors.firstname) : null}
                                        </p>
                                    </div>
                                </div>
                                <div className='col-md-6 px-0 pl-md-2'>
                                    <Field name='lastname' className='w-100' placeholder='Last Name' />
                                    <div className='errorMsg'>
                                        <p className='m-0 text-danger pe-2'>
                                            {errors.lastname && touched.lastname ? (errors.lastname) : null}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='row m-0 p-0'>
                                <div className='col-md-6 px-0 pr-md-2'>
                                    <Field name='email' className='w-100 mt-2' placeholder='Email' />
                                    <div className='errorMsg'>
                                        <p className='m-0 text-danger pe-2'>
                                            {errors.email && touched.email ? (errors.email) : null}
                                        </p>
                                    </div>
                                </div>
                                <div className='col-md-6 px-0 pl-md-2'>
                                    <Field type='number' name='mobile' className='w-100 mt-2' placeholder='Phone' />
                                    <div className='errorMsg'>
                                        <p className='m-0 text-danger pe-2'>
                                            {errors.mobile && touched.mobile ? (errors.mobile) : null}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='row m-0 p-0'>
                                <div className='col-md-6 px-0 pr-md-2'>
                                    <Field as='select' name='country_name' className='w-100 mt-2'>
                                        <option>Country</option>
                                        {
                                            Countries?.map((country, index) => (
                                                <option key={index} value={country?.name} >{country?.name}</option>
                                            ))
                                        }
                                    </Field>
                                    <div className='errorMsg'>
                                        <p className='m-0 text-danger pe-2'>
                                            {errors.country_name && touched.country_name ? (errors.country_name) : null}
                                        </p>
                                    </div>
                                </div>
                                <div className='col-md-6 px-0 pl-md-2'>
                                    <Field name='city' className='w-100 mt-2' placeholder='City' />
                                    <div className='errorMsg'>
                                        <p className='m-0 text-danger pe-2'>
                                            {errors.city && touched.city ? (errors.city) : null}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <Field name='ticket_title' className='w-100 mt-2' placeholder='Subject' />
                            <div className='errorMsg'>
                                <p className='m-0 text-danger pe-2'>
                                    {errors.ticket_title && touched.ticket_title ? (errors.ticket_title) : null}
                                </p>
                            </div>
                            <Field as='textarea' name='description' rows={4} className='w-100 mt-2' placeholder='Your Message' />
                            <div className='errorMsg'>
                                <p className='m-0 text-danger pe-2'>
                                    {errors.description && touched.description ? (errors.description) : null}
                                </p>
                            </div>
                            {
                                isFullSize ? (
                                    <div className='row m-0 p-0'>
                                        <div className='col-md-6 px-0 pr-md-2'></div>
                                        <div className='col-md-6 px-0 pl-md-2'>
                                            <div className='d-sm-flex w-100 align-items-center justify-content-between'>
                                                <div className='d-flex align-items-center pb-3'>
                                                    <div className='p-0 user-select-none captcha-text'>{captchaCode}</div>
                                                    <div className='pl-4 cursor-pointer captcha-text' onClick={captchHandler}>🗘</div>
                                                </div>
                                                <div className='w-100 pl-sm-5'>
                                                    <Field name='captcha_code' className='w-100 mt-2' placeholder='Captcha Code' />
                                                    <div className='errorMsg'>
                                                        <p className='m-0 text-danger pe-2'>
                                                            {errors.captcha_code && touched.captcha_code ? (errors.captcha_code) : null}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='row m-0 p-0'>
                                        <div className='col-md-6 px-0 pr-md-2'>
                                            <div className='d-flex align-items-center mt-3'>
                                                <div className='p-0 captcha-text'>{captchaCode}</div>
                                                <div className='pl-4 cursor-pointer captcha-text' onClick={captchHandler}>🗘</div>
                                            </div>
                                        </div>
                                        <div className='col-md-6 px-0 pl-md-2'>
                                            <Field name='captcha_code' className='w-100 mt-2' placeholder='Captcha Code' />
                                            <div className='errorMsg'>
                                                <p className='m-0 text-danger pe-2'>
                                                    {errors.captcha_code && touched.captcha_code ? (errors.captcha_code) : null}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }

                            <button disabled={isLoading} type='submit' className='btn-blue-bg py-2 w-100 mt-3 d-flex justify-content-center align-items-center'>
                                {`${buttonText || 'Submit'} >`}
                                {isLoading && <div className='loader ml-4'></div>}
                            </button>
                        </Form>
                    )}
                </Formik>
                <div className='devider px-5 w-75 mx-auto my-4'></div>
                {privacyPolicy &&
                    <div className='bg-light-capsule'>
                        <p className='m-0'>We respect your privacy, Read our <span className='text-blue' onClick={() => navigate('/privacyPolicy')}> privacy policy</span></p>
                    </div>
                }
            </div>
        </div>
    )
}
