import React, { useEffect } from 'react';
import './AccountType.scss';
import Layout from '../../../Components/Layout/Layout.js';
import { useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";

export default function AccountType() {

    const navigate = useNavigate();
    const AccountTypeData = [
        { title: 'Razor', description: 'Our withdrawals are carried out in seconds', deposit: '$50 USD', spread: 'Starting From Zero', commission: 'Commission $10/Standard lot', leverage: '1:500', instrument: 'Forex, Metals, Engeries, Indicies, Crypto Majors' },
        { title: 'Ultra', description: 'Our withdrawals are carried out in seconds', deposit: '$50 USD', spread: 'Starting From 1 Pip', commission: 'Zero Commission', leverage: '1:500', instrument: 'Forex, Metals, Engeries, Indicies, Crypto' },
        { title: 'SynthOp', description: 'Our withdrawals are carried out in seconds', deposit: '$50 USD', spread: 'Starting From 1 Pip', commission: 'Zero Commission', leverage: '1:500', instrument: 'Indicies1000, Currency100, USD_Basket, EUR_Basket, Crypto500, Blend_Basket, Fusion_Basket' },
    ];

    const CompareAccountData = [
        { title: 'Razor', lotSizeMin: '0.01', lotSizeMax: '40', noOfLevel: 'Unlimited', hedgedMargin: '0', marginCall: '100', stopOut: '40', orderExecution: 'Market', swapFree: 'No' },
        { title: 'Ultra', lotSizeMin: '0.01', lotSizeMax: 'FOREX-40, METALS -20, CFDs-20', noOfLevel: 'Unlimited', hedgedMargin: '0', marginCall: '100', stopOut: '40', orderExecution: 'Market', swapFree: 'No' },
        { title: 'SynthOp', lotSizeMin: '0.01', lotSizeMax: '40', noOfLevel: 'Unlimited', hedgedMargin: '0', marginCall: '100', stopOut: '40', orderExecution: 'Market', swapFree: 'No' }
    ];

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <Layout>
            <Helmet>
                <title>Account Types</title>
            </Helmet>
            <div className='account-type-container'>
                <div className='main-account-type py-5'>
                    <div className='side-padding py-md-5'>
                        <div>
                            <div className='title-blue mb-4'>
                                <p className='m-0'>Account types</p>
                            </div>
                            <p className='main-title'>We offer multiple account types to suit your trading preferences and strategy.</p>
                            <p className='description mb-0 mt-4'>Our withdrawals are processed in seconds with no manual intervention.</p>
                        </div>
                        <div className='row p-o m-0'>
                            {
                                AccountTypeData?.map((account, index) => ((
                                    <div className={`col-md-4 my-3 py-0 ${index == 0 ? 'pl-md-0' : ''} ${index == 2 ? 'pr-md-0' : ''} my-md-5`} key={index} >
                                        <div className='account-type-card p-3 p-md-4'>
                                            <div className='d-flex'>
                                                <p className='type-capsule px-2 py-1'>Account Type</p>
                                            </div>
                                            <p className='title my-2'>{account?.title}</p>
                                            <p className='description'>{account?.description}</p>
                                            <div className='details-card p-3 mt-4'>
                                                <div>
                                                    <p className='m-0 title-left'>Deposit <span>(Min)</span></p>
                                                    <p className='m-0 value'>{account?.deposit}</p>
                                                </div>
                                                <div>
                                                    <p className='m-0 mt-2 title-left'>Spread</p>
                                                    <p className='m-0 value'>{account?.spread}</p>
                                                </div>
                                                <div>
                                                    <p className='m-0 mt-2 title-left'>Commission</p>
                                                    <p className='m-0 value'>{account?.commission}</p>
                                                </div>
                                                <div>
                                                    <p className='m-0 mt-2 title-left'>Leverage <span>(Min)</span></p>
                                                    <p className='m-0 value'>{account?.leverage}</p>
                                                </div>
                                                <div className='devider my-4'></div>
                                                <div>
                                                    <p className='m-0 title-left'>Instrument</p>
                                                    <p className='m-0 mt-2 text-white'>{account?.instrument}</p>
                                                </div>
                                            </div>
                                            <button className='btn-white-bg mt-5 w-100 py-2' onClick={() => navigate('/openLiveAccount')}>Open Account</button>
                                        </div>
                                    </div>
                                )))
                            }
                        </div>
                    </div>
                </div>

                <div className='complare-container py-5'>
                    <div className='side-padding py-md-5'>
                        <div className='py-4 head'>
                            <div>
                                <div className='title-blue mb-4'>
                                    <p className='m-0'>Compare</p>
                                </div>
                                <p className='main-title'>Account type comparison</p>
                            </div>
                            <div className='text-right w-30 mt-5 mt-md-0'>
                                <p className='head-text mb-4'>Relish our hassle free <span> withdrawals </span> with no <span>manual processing.</span></p>
                                <div className='mt-5 pt-md-3 d-flex justify-content-end'>
                                    <button onClick={() => navigate('/openLiveAccount')} className='btn-blue-bg'>Start Trading Now</button>
                                </div>
                            </div>
                        </div>
                        <div className='row p-o m-0'>
                            {
                                CompareAccountData?.map((account, index) => ((
                                    <div className={`col-md-4 ${index == 0 ? 'pl-md-0' : ''} ${index == 2 ? 'pr-md-0' : ''} my-md-5`} key={index} >
                                        <div className='account-type-card p-3 p-md-4'>
                                            <div className='d-flex'>
                                                <p className='type-capsule px-2 py-1'>Account Type</p>
                                            </div>
                                            <p className='title my-2'>{account?.title}</p>
                                            <div className='devider mt-5 w-75 mx-auto'></div>
                                            <div className='details-card p-3 mt-5'>
                                                <div className='d-flex justify-content-between align-items-center mb-3'>
                                                    <p className='m-0 title-left'>Lot size <span>(Min)</span></p>
                                                    <p className='m-0 value'>{account?.lotSizeMin}</p>
                                                </div>
                                                <div className='d-flex flex-wrap justify-content-between align-items-center mb-3'>
                                                    <p className='m-0 title-left'>Lot size <span>(Max)</span></p>
                                                    <p className='m-0 value'>{account?.lotSizeMax}</p>
                                                </div>
                                                <div className='d-flex justify-content-between align-items-center mb-3'>
                                                    <p className='m-0 title-left'>Max number of levels</p>
                                                    <p className='m-0 value'>{account?.noOfLevel}</p>
                                                </div>
                                                <div className='d-flex justify-content-between align-items-center mb-3'>
                                                    <p className='m-0 title-left'>Hedged margin</p>
                                                    <p className='m-0 value'>{account?.hedgedMargin}%</p>
                                                </div>
                                                <div className='d-flex justify-content-between align-items-center mb-3'>
                                                    <p className='m-0 title-left'>Margin Call</p>
                                                    <p className='m-0 value'>{account?.marginCall}%</p>
                                                </div>
                                                <div className='d-flex justify-content-between align-items-center mb-3'>
                                                    <p className='m-0 title-left'>Stop out</p>
                                                    <p className='m-0 value'>{account?.stopOut}%</p>
                                                </div>
                                                <div className='d-flex justify-content-between align-items-center mb-3'>
                                                    <p className='m-0 title-left'>Order execution</p>
                                                    <p className='m-0 value'>{account?.orderExecution}</p>
                                                </div>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <p className='m-0 title-left'>Swap-free</p>
                                                    <p className='m-0 value'>{account?.swapFree}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Layout >
    )
}
