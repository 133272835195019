import React, { useEffect } from 'react';
import './Crypto.scss';
import Layout from '../../../Components/Layout/Layout.js';
import { useNavigate } from 'react-router-dom';
import useForexData from '../../../Services/useForexData.js';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Helmet } from "react-helmet";

export default function Crypto() {

    const navigate = useNavigate();
    const symbolsToMatch = ['BNBUSD', 'BTCUSD', 'ETHUSD', 'LTCUSD'];
    const cryptoData = useForexData(symbolsToMatch);

    const FeaturesData = [
        { icon: 'Assets/Images/how-it-works-2.svg', title: 'Wide Range', description: 'Options' },
        { icon: 'Assets/Images/wide-range.svg', title: '+154 Pair', description: 'of Coins' },
        { icon: 'Assets/Images/instruments.svg', title: 'Instruments', description: 'Features' },
        { icon: 'Assets/Images/how-it-works-4.svg', title: 'Synthetic Indices', description: 'Forex & crypto markets' }
    ];

    const BenefitsData = [
        { icon: 'Assets/Images/24by7-trading.svg', title: '24/7 Trading', description: 'Enjoy round-the-clock crypto trading, and take advantage of price movements and market opportunities.' },
        { icon: 'Assets/Images/favourable-swaps.svg', title: 'Favourable Swaps', description: 'Get favourable swaps on crypto trading. Hold positions for longer periods without incurring excessive costs.' },
        { icon: 'Assets/Images/competitive-spreads.svg', title: 'Competitive Spreads', description: 'Enjoy competitive spreads on cryptocurrency trading, enter and exit positions with minimal costs.' },
        { icon: 'Assets/Images/extensive-funding.svg', title: 'Flexible Margins', description: 'Manage the risk effectively with fixed margins and set appropriate stop-loss and take-profit levels.' },
    ];

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const statusIdentifier = (current, previous, digit) => {
        if (current?.toFixed(digit || 3) > previous?.toFixed(digit || 3)) {
            return '#28a745a1';
        } else {
            return '#ff00188c';
        }
    }

    return (
        <Layout>
            <Helmet>
                <title>Crypto</title>
            </Helmet>
            <div className='instruments-crypto-container'>
                <div className='crypto-container py-5'>
                    <div className='side-padding py-5'>
                        <div className='py-4 head'>
                            <div>
                                <div className='title-white mb-4'>
                                    <p className='m-0'>Crypto Currency </p>
                                </div>
                                <p className='main-title'>Enter the world of crypto trading with FXOpulence</p>
                            </div>
                            <div className='text-right w-30 mt-5 mt-md-0'>
                                <p className='overview-head-text mb-4'>Experience the power of Crypto Trading on FXOpulence's reliable and innovative platform.</p>
                                <div className='mt-5 pt-md-5 d-flex justify-content-end'>
                                    <button onClick={() => navigate('/openLiveAccount')} className='btn-white-bg mt-md-5'>Open Live Account</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='overview-container py-5'>
                    <div className='side-padding py-5'>
                        <div>
                            <div className='title-blue mb-4'>
                                <p className='m-0'>Overview</p>
                            </div>
                            <p className='main-title'>Enjoy <span>low trading costs</span> for the best of crypto markets</p>
                            <p className='description mb-0 mt-4'>Trade with confidence in the dynamic world of cryptocurrency with FXOpulence's innovative and reliable trading platform.</p>
                            <button className='mt-5 btn-blue-bg' onClick={() => navigate('/openLiveAccount')}>Start Trading Now</button>
                        </div>
                    </div>
                </div>
                <div className='start-now'>
                    <div className='feature-container-bg-color'>
                        <div className='side-padding py-4'>
                            <div className='feature-container'>
                                {
                                    FeaturesData?.map((feature, index) => (
                                        <div className='feature-card my-3' key={index}>
                                            <div className='d-flex'>
                                                <div>
                                                    <div className='icon-wrapper'>
                                                        <img height={28} className='cursor-pointer' src={feature?.icon} alt='icon' />
                                                    </div>
                                                </div>
                                                <p className='title m-0 ml-3'>{feature?.title}</p>
                                            </div>
                                            <p className='m-0 mt-1 description'>{feature?.description}</p>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className='side-padding py-md-5'>
                        <div className='row px-0 py-5 m-0'>
                            <div className='col-md-5 pl-md-0 pr-md-4 d-flex align-items-center'>
                                <div>
                                    <div className='title-blue mb-4'>
                                        <p className='m-0'>Start Now</p>
                                    </div>
                                    <p className='main-title'>Seamlessly trade cryptocurrencies with us</p>
                                    <p className='description m-0'>Trade the most popular currencies on our platform</p>
                                </div>
                            </div>
                            <div className='col-md-7 pr-md-0 pl-md-4 mt-4 mt-md-0 d-flex align-items-center w-100'>
                                <div className='symbol-table-container p-4'>
                                    <div className='table-responsive'>
                                        <table className='table borderless w-100 m-0'>
                                            <thead>
                                                <tr className='py-1'>
                                                    <th>Symbol</th>
                                                    <th>Bid Price</th>
                                                    <th>Ask Price</th>
                                                    <th>Spread</th>
                                                </tr>
                                            </thead>
                                            {
                                                cryptoData?.length > 0 ? (
                                                    <tbody>
                                                        {
                                                            cryptoData?.map((symbol, index) => (
                                                                <tr key={index}>
                                                                    <td><div className='py-1'>{symbol?.Symbol}</div></td>
                                                                    <td><div className='d-flex align-items-center justify-content-center' ><p className='m-0 rounded text-white px-2 py-1' style={{ backgroundColor: statusIdentifier(symbol?.bid, symbol?.prevBid, symbol?.digit || 3) }}>{(symbol?.bid)?.toFixed(symbol?.digit || 3)}</p></div></td>
                                                                    <td><div className='d-flex align-items-center justify-content-center' ><p className='m-0 rounded text-white px-2 py-1' style={{ backgroundColor: statusIdentifier(symbol?.ask, symbol?.prevAsk, symbol?.digit || 3) }}>{(symbol?.ask)?.toFixed(symbol?.digit || 3)}</p></div></td>
                                                                    <td><div className='py-1'>{Math.round(symbol?.spread)}</div></td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                ) : (
                                                    <tbody>
                                                        {
                                                            Array.from({ length: 5 }, (_, index) => (
                                                                <tr key={index}>
                                                                    <td><Skeleton height={32} /></td>
                                                                    <td><Skeleton height={32} /></td>
                                                                    <td><Skeleton height={32} /></td>
                                                                    <td><Skeleton height={32} /></td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                )
                                            }
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='benefits-container py-5'>
                        <div className='side-padding py-md-5'>
                            <div className='head'>
                                <div className='text-left'>
                                    <p className='head-text w-30 mb-4'>Maximize your profits with low fees and competitive spreads on crypto</p>
                                    <div className='mt-5 d-flex'>
                                        <button className='btn-blue-bg' onClick={() => navigate('/openLiveAccount')} >Open Live Account</button>
                                    </div>
                                </div>
                                <div className='mt-5 mt-md-0'>
                                    <div className='title-blue mb-4'>
                                        <p className='m-0'>Benefits</p>
                                    </div>
                                    <p className='main-title'>Revolutionise your crypto trading with FXOpulence</p>
                                </div>
                            </div>
                            <div className='row m-0 px-0 pt-5'>
                                {
                                    BenefitsData?.map((benefit, index) => (
                                        <div key={index} className={`col-md-6 my-4 ${index % 2 == 0 ? 'pl-md-0 pr-md-4' : 'pr-md-0 pl-md-4'}`}>
                                            <div className='benefit-card py-4 pr-4'>
                                                <div className='icon-wrapper'>
                                                    <img height={32} className='cursor-pointer' src={benefit?.icon} alt='icon' />
                                                </div>
                                                <div className='pl-4'>
                                                    <p className='title my-3'>{benefit?.title}</p>
                                                    <p className='description m-0'>{benefit?.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
