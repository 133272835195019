import React, { useEffect } from 'react';
import './RiskDisclosure.scss';
import Layout from '../../Components/Layout/Layout.js';
import { Helmet } from "react-helmet";

export default function RiskDisclosure() {

  const RiskDisclosureData = {
    mainInformation: 'This risk disclosure is intended to inform you of the risks in trading activities on financial markets. You must recognize that these risks entail a chance of losing money while trading. This disclosure is informational and must not be considered a list of all possible risks.',
    menus: [
      {
        title: 'Use of leverage',
        subMenus: [
          'In margin trading, leverage can significantly impact your trading account, even with minor changes in exchange rates and instrument prices. You must understand that if the market moves in the opposite direction to your open positions, your chances of losing your initial deposit and other funds intended to maintain your open positions are very high. You are fully responsible for using your funds and accounting for the risks.',
        ]
      },
      {
        title: 'Instability of financial instruments',
        subMenus: [
          'The quotes of most instruments traded on financial markets may vary considerably over a day, which may bring you profit and loss.',
        ]
      },
      {
        title: 'Technical risks',
        subMenus: [
          'We are not responsible for financial losses arising from malfunctions in your electronic communication or information systems.',
          'When working with the client terminal, you assume the risks arising from:',
          'Failures in your equipment, software, and connection.',
          'Errors in your client terminal settings.',
          'Please update your version of the client terminal in a timely manner.',
          'You need to follow the instructions for using the client terminal.',
        ]
      },
      {
        title: 'We are not responsible for errors that occur in the operation of the client terminal and will not compensate losses resulting from errors in the process of the client terminal because we have a non-exclusive right to use the MetaTrader trading system (client terminal) for a specific purpose and do not have the right to provide technical support.',
      },
      {
        title: 'Market risks',
        subMenus: [
          'You agree that the time required to process your orders and instructions may increase if market conditions become abnormal.',
        ]
      },
      {
        title: 'Risks associated with the laws of individual governments',
        subMenus: [
          'You also assume responsibility for trading and non-trading operations performed within countries where they are restricted or prohibited by law.',
        ]
      },
      {
        title: 'Risks associated with the trading platform',
        subMenus: [
          'All of your instructions are sent to our server and executed in order. Therefore, you can only send a second order once your previous order has been executed. If a second order is received before the first is processed, the second order will be rejected. You assume responsibility for any unplanned trading operation that may be executed if you resubmit an order before being notified of the results of the first order.',
          'You must understand that closing the order or position window does not cancel a submitted order.',
          "You acknowledge that only the quotes received from our server are authoritative. If there is a problem in the connection between your client terminal and our server, you can retrieve undelivered quote data from the client terminal's quote database.",
        ]
      },
      {
        title: 'Communication risks',
        subMenus: [
          'You must be aware of the risk that unauthorized parties may access information sent via unencrypted email.',
          'We are not responsible for financial losses arising from delayed or failed receipt of a company message.',
          '3 You are responsible for the security of the credentials for your Personal Area and trading accounts and the confidential information we send you. We are not responsible for financial losses arising from your disclosure of this information to third parties.',
        ]
      },
      {
        title: 'Force majeure events',
        subMenus: [
          'We are not responsible for financial losses arising from force majeure events. These events are extreme and irresistible circumstances that are independent of the will and actions of the agreement participants that cannot be foreseen, prevented, or eliminated, including but not limited to natural disasters, fires, man-made accidents and disasters, emergencies at utility works and on utility lines, DDOS attacks, riots, military actions, terrorist attacks, uprisings, civil unrest, strikes, and the regulatory acts of state- and local government authorities.',
        ]
      },
    ]
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>Risk Disclosure</title>
      </Helmet>
      <div className='privacy-policy-container'>
        <div className='privacy-policy-header'>
          <div className='side-padding py-0'>
            <div className='row m-0 p-0'>
              <div className='py-5 col-md-6 p-0'>
                <div className='py-md-5'>
                  <div className='title-blue mb-4 '>
                    <p className='m-0'>FXOpulence</p>
                  </div>
                  <p className='main-text mb-4'>Risk Disclosure</p>
                </div>
              </div>
              <div className='col-md-6 p-0 d-flex justify-content-end'>
                <div className='privacy-policy-bg'></div>
              </div>
            </div>
          </div>
        </div>
        <div className='detailed-info py-5'>
          <div className='side-padding py-md-5'>
            <p className='mb-4'>{RiskDisclosureData?.mainInformation}</p>
            <ol className='m-0 pl-4' type="1">
              {
                RiskDisclosureData?.menus?.map((menu, index) => (
                  <>
                    <li key={index}>{menu?.title}</li>
                    <ol className='m-0 pl-4' type="a">
                      {
                        menu?.subMenus?.map((subMenus, index) => (
                          <li key={index}>{subMenus}</li>
                        ))
                      }
                    </ol>
                  </>
                ))
              }
            </ol>
          </div>
        </div>
      </div>
    </Layout>
  )
}
