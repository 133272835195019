import React, { useEffect, useState } from 'react';
import './FaqsContainer.scss';
import { Accordion } from 'react-bootstrap';

export default function FaqsContainer() {

    const FaqDepositData = [
        { question: 'Why Hasn’t My Deposit Been Credited?', answer: 'Your deposit will be successfully credited once the transaction meets the minimum required network confirmation specified by relevant network. Please note that the minimum requirement is different for each cryptocurrency.' },
        { question: 'Why Hasn’t My Withdrawal Arrived?', answer: 'Your withdrawal will be successfully credited once the transaction meets the minimum required network confirmation specified by the respective network. Please note that the minimum requirement is different for each cryptocurrency.' },
        { question: 'How to Find My Transaction ID (TxID)?', answer: `To locate your Transaction ID (TxID), navigate to the transaction history section in your FXO Portal. There, you'll find a list of all your transactions along with their respective TxIDs. Simply click on the specific transaction, and you'll see the TxID associated with it.` },
        { question: 'How many account types does FXO offer?', answer: 'FXO rolls out a trio of account options for its clients: Razor, Ultra, and SynthOp. Crave a detailed comparison? Direct yourself to our dedicated page.' }
    ];

    const FaqWithdrawalData = [
        { question: `What's the min deposit & withdrawal limit at FXO?`, answer: `At FXO, we've standardized our minimum limit for both deposits and withdrawals at $50 USD, ensuring straightforward and accessible trading for all.` },
        { question: 'Which payment methods are supported by FXO?', answer: 'At FXO, we champion decentralized crypto transactions, completely sidelining human intervention. Our current offerings include ETH, BEP-20, TRC-20, and BTC, providing our users with both flexibility and enhanced security.' },
        { question: 'How I Deposit/Withdraw Cryptocurrency on FXO?', answer: 'To deposit or withdraw cryptocurrency on FXO, follow these steps: 1) Log into your FXO account., 2) Navigate to the "Payments" section., 3) Select the deposit or withdrawal method and the cryptocurrency you wish to use., 4) Follow the on-screen instructions to complete your transaction, and make sure you use the correct wallet address when depositing or withdrawing.' },
        { question: 'What asset types can I trade with FXO?', answer: 'FXO throws open doors to an expansive asset array - encompassing forex, cryptocurrencies, indices, metals, CFDs, and synthetic indices. ' }
    ];

    return (
        <div className='faqs-container'>
            <div className='side-padding'>
                <div className='my-0 my-md-5 pt-5'>
                    <div className='title-blue'>
                        <p className='m-0'>FAQs</p>
                    </div>
                    <div className='mt-3'>
                        <p className='faq-title mb-4'>Frequently Asked Questions</p>
                        <p className='description'>Most common questions.</p>
                    </div>
                </div>
                <div className='row w-100 m-0 p-0 mb-5'>
                    <Accordion className='col-md-6 w-100 pl-md-0'>
                        {
                            FaqDepositData?.map((question, index) => (
                                <Accordion.Item eventKey={index}>
                                    <Accordion.Header>{question?.question}</Accordion.Header>
                                    <Accordion.Body>
                                        {question?.answer}
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))
                        }
                    </Accordion>
                    <Accordion className='col-md-6 pr-md-0'>
                        {
                            FaqWithdrawalData?.map((question, index) => (
                                <Accordion.Item eventKey={index}>
                                    <Accordion.Header>{question?.question}</Accordion.Header>
                                    <Accordion.Body>
                                        {question?.answer}
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))
                        }
                    </Accordion>
                </div>
            </div>
        </div>
    )
}
