import { useEffect, useState } from 'react';
import MarketData from './MarketData.js'

const useForexData = (symbolsToMatch) => {
    const [forexData, setForexData] = useState([]);

    const getData = async () => {
        const data = await MarketData.getLTP(symbolsToMatch);
        setForexData(data);
    }

    useEffect(() => {
        getData();
    }, [])

    useEffect(() => {
        const socket = new WebSocket(process.env.REACT_APP_MARKET_DATA_SOCKET);

        socket.onopen = () => {
            socket.send(JSON.stringify({ symbols: symbolsToMatch }))
        };

        socket.onmessage = (event) => {

            const newDataObject = JSON.parse(event.data);

            // Convert object to array
            const newDataArray = ([newDataObject]);

            // Filter data based on symbolsToMatch
            const filteredData = newDataArray.filter(item => symbolsToMatch.includes(item.Symbol));

            // Update forexData array
            setForexData(prevData => {
                const updatedDataArray = [...prevData];

                filteredData.forEach(newItem => {
                    let prevObj;
                    updatedDataArray.filter((item) => {
                        if (item.Symbol === newItem.Symbol) {
                            prevObj = item;
                        }
                    });
                    const index = updatedDataArray.findIndex(item => item.Symbol === newItem.Symbol);

                    if (index !== -1) {
                        // Symbol exists, replace the object
                        updatedDataArray[index] = { ...newItem, prevAsk: prevObj?.ask, prevBid: prevObj?.bid };
                    } else {
                        // Symbol doesn't exist, add the new object
                        updatedDataArray.push({ ...newItem, prevAsk: prevObj?.ask, prevBid: prevObj?.bid });
                    }
                });

                return updatedDataArray;
            });
        };

        return () => {
            // socket.send(JSON.stringify({ "message_type": "unsubscribe_tick_event" }));
            socket.close();
        };
    }, []);

    const sortData = (data) => {
        const sortedArray = data.slice().sort((a, b) => {
            const symbolA = a?.symbol?.toUpperCase();
            const symbolB = b?.symbol?.toUpperCase();

            if (symbolA < symbolB) {
                return -1;
            }
            if (symbolA > symbolB) {
                return 1;
            }
            return 0;
        });

        return sortedArray;
    }

    return sortData(forexData);
};

export default useForexData;