import Home from '../../Pages/Home/Home.js';
import Partnership from '../../Pages/Partnership/Partnership.js';
import ContactUs from '../../Pages/ContactUs/ContactUs.js';
import OpenLiveAccount from '../../Pages/OpenLiveAccount/OpenLiveAccount.js';
import OpenDemoAccount from '../../Pages/OpenDemoAccount/OpenDemoAccount.js';
import Payments from '../../Pages/Payments/Payments.js';
import Forex from '../../Pages/Instruments/Forex/Forex.js';
import Metal from '../../Pages/Instruments/Metal/Metal.js';
import Crypto from '../../Pages/Instruments/Crypto/Crypto.js';
import CFDs from '../../Pages/Instruments/CFDs/CFDs.js';
import Indices from '../../Pages/Instruments/Indices/Indices.js';
import SyntheticIndices from '../../Pages/Instruments/SyntheticIndices/SyntheticIndices.js';
import MT5 from '../../Pages/Platforms/MT5/MT5.js';
import AccountType from '../../Pages/Platforms/AccountType/AccountType.js';
import AboutUs from '../../Pages/AboutUs/AboutUs.js';
import PrivacyPolicy from '../../Pages/PrivacyPolicy/PrivacyPolicy.js';
import RiskDisclosure from '../../Pages/RiskDisclosure/RiskDisclosure.js';
import ExecutionRisk from '../../Pages/ExecutionRisk/ExecutionRisk.js';
import Blogs from '../../Pages/Blogs/Blogs.js';
import SingleBlog from '../../Pages/Blogs/SingleBlog/SingleBlog.js';

export const RoutesData = [
    {
        path: "/",
        element: Home,
    },
    {
        path: "/payments",
        element: Payments,
    },
    {
        path: "/forex",
        element: Forex,
    },
    {
        path: "/crypto",
        element: Crypto,
    },
    {
        path: "/metals",
        element: Metal,
    },
    {
        path: "/cfds",
        element: CFDs,
    },
    {
        path: "/indices",
        element: Indices,
    },
    {
        path: "/syntheticIndices",
        element: SyntheticIndices,
    },
    {
        path: "/mt5",
        element: MT5,
    },
    {
        path: "/accountType",
        element: AccountType,
    },
    {
        path: "/partnership",
        element: Partnership,
    },
    {
        path: "/contactUs",
        element: ContactUs,
    },
    {
        path: "/openLiveAccount",
        element: OpenLiveAccount,
    },
    {
        path: "/openDemoAccount",
        element: OpenDemoAccount,
    },
    {
        path: "/aboutUs",
        element: AboutUs,
    },
    {
        path: "/privacyPolicy",
        element: PrivacyPolicy,
    },
    {
        path: "/riskDisclosure",
        element: RiskDisclosure,
    },
    {
        path: "/executionRisk",
        element: ExecutionRisk,
    },
    {
        path: "/blogs",
        element: Blogs,
    },
    {
        path: "blog",
        element: SingleBlog,
    },
]