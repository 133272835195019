import React, { useEffect } from 'react';
import './ContactUs.scss';
import ContactCard from './Components/ContactCard';
import FaqsContainer from './Components/FaqsContainer';
import ContactForm from './Components/ContactContainer';
import Layout from '../../Components/Layout/Layout.js';
import { Helmet } from "react-helmet";

export default function ContactUs() {

    const ContactData = [
        { title: 'Call Support', value: '+971 44578904', icon: 'Assets/Images/phone-blue.svg' },
        { title: 'Raise a ticket', value: 'support@fxopulence.com', icon: 'Assets/Images/mail-blue.svg' },
        { title: 'Read most common questions', value: 'Read FAQs', icon: 'Assets/Images/global-blue.svg' }
    ];

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <Layout>
            <Helmet>
                <title>Support Center</title>
            </Helmet>
            <div className='contact-us-container'>
                <div className='side-padding'>
                    <div className='head mt-5 pt-5'>
                        <div className='title-blue'>
                            <p className='m-0'>Contact</p>
                        </div>
                        <div className='mt-4'>
                            <p className='main-text mb-4'>Let’s talk</p>
                            <p className='m-0 description'>Are you an existing or new user to trade? We are here to answer your questions and resolve issues 24/7.</p>
                        </div>
                    </div>
                    <div className='contact-card-wrapper my-0 py-5 my-md-5'>
                        {
                            ContactData?.map((item, index) => (
                                <ContactCard key={index} data={item} />
                            ))
                        }
                    </div>
                </div>
                <div>
                    <FaqsContainer />
                </div>
                <div>
                    <ContactForm />
                </div>
            </div>
        </Layout>
    )
}
