import React, { Component } from 'react';
import ApiClient from './ApiClient.js';

export default class MarketData extends Component {
    static async getLTP(symbolsToMatch) {

        return ApiClient.post('', { symbols: symbolsToMatch }, process.env.REACT_APP_MARKET_DATA_API, { headers: { 'Content-Type': 'application/json' } }).then(res => {
            if (!(res?.error)) {
                return res?.ticks;
            } else {
                return [];
            }
        })
    }
}
