export const NavbarData = [
    {
        title: 'Payments',
        url: '/payments',
        activeUrl: ['/payments'],
    },
    {
        title: 'Instruments',
        activeUrl: ['/forex', '/metals', '/crypto', '/cfds', '/indices', '/syntheticIndices'],
        children: [
            {
                title: 'Forex',
                url: '/forex'
            },
            {
                title: 'Crypto',
                url: '/crypto'
            },
            {
                title: 'Metals',
                url: '/metals'
            },
            {
                title: 'CFDs',
                url: '/cfds'
            },
            {
                title: 'Indices',
                url: '/indices'
            },
            {
                title: 'Synthetic Indices',
                url: '/syntheticIndices'
            },
        ]
    },
    {
        title: 'Platform',
        activeUrl: ['/mt5', '/accountType'],
        children: [
            {
                title: 'Meta Trader 5',
                url: '/mt5'
            },
            {
                title: 'Account Type',
                url: '/accountType'
            },
        ]
    },
    {
        title: 'Partnership',
        url: '/partnership',
        activeUrl: ['/partnership'],
    },
    {
        title: 'More',
        activeUrl: ['/contactUs', '/aboutUs', '/openLiveAccount', '/openDemoAccount', '/blog'],
        children: [
            {
                title: 'About Us',
                url: '/aboutUs'
            },
            {
                title: 'Contact Us',
                url: '/contactUs'
            },
            {
                title: 'Blog',
                url: '/blogs'
            },
        ]
    },
]