import React, { useEffect } from 'react';
import './Forex.scss';
import Layout from '../../../Components/Layout/Layout.js';
import { useNavigate } from 'react-router-dom';
import useForexData from '../../../Services/useForexData.js';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Helmet } from "react-helmet";

export default function Forex() {

    const navigate = useNavigate();
    const symbolsToMatch = ['AUDCAD', 'AUDCHF', 'AUDJPY', 'AUDNZD', 'AUDUSD', 'CADCHF', 'CADJPY', 'CHFJPY', 'EURAUD', 'EURCAD', 'EURCHF', 'EURGBP', 'EURJPY', 'EURNZD', 'EURUSD', 'GBPAUD', 'GBPCAD', 'GBPCHF', 'GBPJPY', 'GBPNZD', 'GBPUSD', 'NZDCAD', 'NZDCHF', 'NZDJPY', 'NZDUSD', 'USDCAD', 'USDCHF', 'USDJPY'];
    const forexData = useForexData(symbolsToMatch);

    const FeaturesData = [
        { icon: 'Assets/Images/how-it-works-2.svg', title: 'Wide Range', description: 'Options' },
        { icon: 'Assets/Images/wide-range.svg', title: '+154 Pair', description: 'of Coins' },
        { icon: 'Assets/Images/instruments.svg', title: 'Instruments', description: 'Features' },
        { icon: 'Assets/Images/how-it-works-4.svg', title: 'Synthetic Indices', description: 'Forex & crypto markets' }
    ];

    const BenefitsData = [
        { icon: 'Assets/Images/advanced-trading-tools.svg', title: 'Advanced TradingTools', description: 'Access a wide range of advanced trading tools such as charting software, technical analysis indicators, and risk management tools.' },
        { icon: 'Assets/Images/regulated-broker.svg', title: 'Low Spreads', description: 'FXOpulence offers some of the lowest spreads in the industry, ensuring that you can enter and exit positions with minimal costs.' },
        { icon: 'Assets/Images/wide-range.svg', title: 'High Liquidity', description: 'The high liquidity of the Forex market also means that your trades can be executed quickly and efficiently with FXOpulence.' },
        { icon: 'Assets/Images/user-friendly-platform.svg', title: 'User-Friendly Platform', description: 'Designed to make Forex trading accessible and easy for traders of all levels, according to your preferences and needs.' },
    ];

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const statusIdentifier = (current, previous, digit) => {
        if (current?.toFixed(digit || 3) > previous?.toFixed(digit || 3)) {
            return '#28a745a1';
        } else {
            return '#ff00188c';
        }
    }

    return (
        <Layout>
            <Helmet>
                <title>Forex</title>
            </Helmet>
            <div className='instruments-forex-container'>
                <div className='forex-container py-5'>
                    <div className='side-padding py-5'>
                        <div className='py-4 head'>
                            <div>
                                <div className='title-white mb-4'>
                                    <p className='m-0'>Forex</p>
                                </div>
                                <p className='main-title'>Experience superior forex trading with FXOpulence</p>
                            </div>
                            <div className='text-right w-30 mt-5 mt-md-0'>
                                <p className='overview-head-text mb-4'>Trade with ease and unlock unlimited earning potential with our trusted brokerage.</p>
                                <div className='mt-5 pt-md-5 d-flex justify-content-end'>
                                    <button onClick={() => navigate('/openLiveAccount')} className='btn-white-bg mt-md-5'>Open Live Account</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='overview-container py-5'>
                    <div className='side-padding py-5'>
                        <div>
                            <div className='title-blue mb-4'>
                                <p className='m-0'>Overview</p>
                            </div>
                            <p className='main-title'>Unlock Exciting Benefits Of Forex Currency Trading With <span>The #1 Forex Broker</span></p>
                            <p className='description mb-0 mt-4'>Get ready to dive into the dynamic world of Forex trading and unlock unlimited earning potential, with FXOpulence’s expertize.</p>
                            <button className='mt-5 btn-blue-bg' onClick={() => navigate('/openLiveAccount')}>Start Trading Now</button>
                        </div>
                    </div>
                </div>
                <div className='start-now'>
                    <div className='feature-container-bg-color'>
                        <div className='side-padding py-4'>
                            <div className='feature-container'>
                                {
                                    FeaturesData?.map((feature, index) => (
                                        <div className='feature-card my-3' key={index}>
                                            <div className='d-flex'>
                                                <div>
                                                    <div className='icon-wrapper'>
                                                        <img height={28} className='cursor-pointer' src={feature?.icon} alt='icon' />
                                                    </div>
                                                </div>
                                                <p className='title m-0 ml-3'>{feature?.title}</p>
                                            </div>
                                            <p className='m-0 mt-1 description'>{feature?.description}</p>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className='side-padding py-md-5'>
                        <div className='row px-0 py-5 m-0'>
                            <div className='col-md-5 pl-md-0 pr-md-4 d-flex align-items-center'>
                                <div>
                                    <div className='title-blue mb-4'>
                                        <p className='m-0'>Start Now</p>
                                    </div>
                                    <p className='main-title'>Start trading forex currencies like a pro</p>
                                    <p className='description m-0'>Trade the most popular Forex pairs on MT5.</p>
                                </div>
                            </div>
                            <div className='col-md-7 pr-md-0 pl-md-4 mt-4 mt-md-0 d-flex align-items-center w-100'>
                                <div className='symbol-table-container p-4'>
                                    <div className='table-responsive table-body-scrollable'>
                                        <table className='table borderless w-100 m-0'>
                                            <thead>
                                                <tr className='py-1'>
                                                    <th>Symbol</th>
                                                    <th>Bid Price</th>
                                                    <th>Ask Price</th>
                                                    <th>Spread</th>
                                                </tr>
                                            </thead>
                                            {
                                                forexData?.length > 0 ? (
                                                    <tbody>
                                                        {
                                                            forexData?.map((symbol, index) => (
                                                                <tr key={index}>
                                                                    <td><div className='py-1'>{symbol?.Symbol}</div></td>
                                                                    <td><div className='d-flex align-items-center justify-content-center' ><p className='m-0 rounded text-white px-2 py-1' style={{ backgroundColor: statusIdentifier(symbol?.bid, symbol?.prevBid, symbol?.digit || 3) }}>{(symbol?.bid)?.toFixed(symbol?.digit || 3)}</p></div></td>
                                                                    <td><div className='d-flex align-items-center justify-content-center' ><p className='m-0 rounded text-white px-2 py-1' style={{ backgroundColor: statusIdentifier(symbol?.ask, symbol?.prevAsk, symbol?.digit || 3) }}>{(symbol?.ask)?.toFixed(symbol?.digit || 3)}</p></div></td>
                                                                    <td><div className='py-1'>{Math.round(symbol?.spread)}</div></td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                ) : (
                                                    <tbody>
                                                        {
                                                            Array.from({ length: 6 }, (_, index) => (
                                                                <tr key={index}>
                                                                    <td><Skeleton height={25} /></td>
                                                                    <td><Skeleton height={25} /></td>
                                                                    <td><Skeleton height={25} /></td>
                                                                    <td><Skeleton height={25} /></td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                )
                                            }
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='benefits-container py-5'>
                        <div className='side-padding py-md-5'>
                            <div className='head'>
                                <div className='text-left'>
                                    <p className='head-text w-30 mb-4'>Switch to FXOpulence and tap into the cutting-edge technology.</p>
                                    <div className='mt-5 d-flex'>
                                        <button className='btn-blue-bg' onClick={() => navigate('/openLiveAccount')} >Open Live Account</button>
                                    </div>
                                </div>
                                <div className='mt-5 mt-md-0'>
                                    <div className='title-blue mb-4'>
                                        <p className='m-0'>Benefits</p>
                                    </div>
                                    <p className='main-title'>Unlock Your Trading Potential With FXOpulence</p>
                                </div>
                            </div>
                            <div className='row m-0 px-0 pt-5'>
                                {
                                    BenefitsData?.map((benefit, index) => (
                                        <div key={index} className={`col-md-6 my-4 ${index % 2 == 0 ? 'pl-md-0 pr-md-4' : 'pr-md-0 pl-md-4'}`}>
                                            <div className='benefit-card py-4 pr-4'>
                                                <div className='icon-wrapper'>
                                                    <img height={32} className='cursor-pointer' src={benefit?.icon} alt='icon' />
                                                </div>
                                                <div className='pl-4'>
                                                    <p className='title my-3'>{benefit?.title}</p>
                                                    <p className='description m-0'>{benefit?.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}