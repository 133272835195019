import React, { useEffect, useState } from 'react';
import './SingleBlog.scss';
import Layout from '../../../Components/Layout/Layout.js'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { BlogData } from './BlogData/BlogData.js';

export default function SingleBlog() {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const blogId = searchParams.get('id');
    const [currentBlog, setCurrentBlog] = useState();

    useEffect(() => {
        let blog;
        BlogData.filter((item) => {
            if (blogId == item.id) {
                blog = item;
            }
        });
        setCurrentBlog(blog);
    }, [blogId])

    const navigateTo = (id) => {
        navigate(`/blog?id=${id}`);
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <Layout>
            <Helmet>
                {/* ------------ Need to replace with blog's title while implementing api ------------ */}
                <title>Blog | Weekly crypto inflows total $78 mln the largest since July</title>
            </Helmet>
            <div className='single-blog-container'>
                <div className='d-flex justify-content-center py-5'>
                    <div className='blog-view py-md-5'>
                        <div className='title-blue mb-4 '>
                            <p className='m-0'>{currentBlog?.category}</p>
                        </div>
                        <p className='main-text mb-3'>{currentBlog?.title}</p>
                        <div className='d-flex align-items-center flex-wrap'>
                            <button className='btn-back mr-3' onClick={() => navigate('/blogs')}>🡠 Back to all</button>
                            <p className='date-text m-0 mr-3'>{currentBlog?.date}</p>
                        </div>
                        <div className='my-5'>
                            <img className='blog-img' src={currentBlog?.img} alt='blog-img' />
                        </div>

                        {/* ---------- Temporary removed ---------- */}
                        {/* <div className='blog-profile-card py-4'>
                            <div className='row p-0 m-0'>
                                <div className='col-md-5 pl-0'>
                                    <div className='d-flex align-items-center'>
                                        <div className='icon-wrapper'>
                                            <img height={64} className='cursor-pointer' src='Assets/Images/Profile.svg' alt='icon' />
                                        </div>
                                        <div className='ml-3'>
                                            <p className='m-0 name'>Aleksandra Kwiecien</p>
                                            <p className='m-0 role'>Content manager</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-7 mt-3 mt-md-0'>
                                    <p className='m-0 description'>Marketing and content manager. Passionate about new technologies that make our everyday life easier, and people who create them.</p>
                                </div>
                            </div>
                        </div> */}

                        <div className='blog-details mt-4' dangerouslySetInnerHTML={{ __html: currentBlog?.description }}></div>
                    </div>
                </div>

                <div className='relates-news-container py-5'>
                    <div className='side-padding py-md-5'>
                        <p className='title mb-5'>Relates News</p>
                        <div className='row p-0 m-0'>
                            {BlogData?.map((news, index) => (
                                <div className='my-3 col-md-4' key={index}>
                                    <div className='news-card'>
                                        <img src={news?.img} alt='news-img' />
                                        <div className='title-wrapper d-flex align-items-center mt-4'>
                                            <p className='m-0 title'>{news?.category}</p>
                                            <p className='m-0 ml-3 date-text'>{news?.date}</p>
                                        </div>
                                        <div className='p-md-3 p-2'>
                                            <p className='mt-4 title'>{news?.title}</p>
                                            <button className='btn-read-more mb-2 pl-0' onClick={() => navigateTo(news?.id)} >{`Read more >`}</button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className='start-with-us pt-5'>
                    <div className='side-padding'>
                        <div className='py-5 head'>
                            <div>
                                <div className='title-blue mb-4'>
                                    <p className='m-0'>Get Started with us</p>
                                </div>
                                <p className='main-title'>Ready to step into the trading world? Open an account within minutes!</p>
                            </div>
                            <div className='text-right w-30 mt-5 mt-md-0'>
                                <p className='head-text mb-4'>Get revolutionary <span>insights</span> and easy-to-use <span>trading tools to</span> relish a <span>smooth-sailing trading</span> journey.</p>
                                <div className='mt-4 d-flex justify-content-end'>
                                    <button className='btn-blue-bg' onClick={() => navigate('/openLiveAccount')}>Start trading now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='marquee-container py-1'>
                        <marquee className='pb-0 pt-1 m-0'>
                            <div className='d-flex'>
                                <div className='d-flex align-items-center my-2 mr-4'><img className='mr-2' src='Assets/Images/true-badge.svg' alt='icon' /><p className='m-0'>Secure your funds with robust cybersecurity innovations</p></div>
                                <div className='d-flex align-items-center my-2 mr-4'><img className='mr-2' src='Assets/Images/true-badge.svg' alt='icon' /><p className='m-0'>Secure your funds with robust cybersecurity innovations</p></div>
                                <div className='d-flex align-items-center my-2'><img className='mr-2' src='Assets/Images/true-badge.svg' alt='icon' /><p className='m-0'>Trade over 200 CFDs and other assets like Forex, Crypto, Metals, Indices</p></div>
                            </div>
                        </marquee>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
