import React, { useEffect } from 'react';
import './MT5.scss';
import Layout from '../../../Components/Layout/Layout.js';
import { useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";

export default function MT5() {

    const navigate = useNavigate();

    const BenefitsData = [
        { icon: 'Assets/Images/favourable-swaps.svg', title: 'MetaTrader 5 (MT5)', description: 'Trade CFDs on your favorite trading instruments using the MetaTrader 5. A powerful platform for currency pairs and other financial instruments CFD trading, the MetaTrader 5 is free to download on FXOpulence.' },
        { icon: 'Assets/Images/meta-editor.svg', title: 'MetaEditor', description: 'On MetaTrader 5, you can develop trading robots and technical indicators through the specialized MetaEditor tool. As the tool is linked with the platform, new programs will automatically appear in your MetaTrader 5 and can be executed instantly.' },
        { icon: 'Assets/Images/hedging-system.svg', title: 'Hedging System', description: 'In MetaTrader 5 with FXOpulence, you can experience trading using the hedging mode system. Hedging allows you to open multiple positions, even exact opposite positions, for a trading instrument.' },
        { icon: 'Assets/Images/diversification.svg', title: 'Charts & Timeframes', description: 'Visually monitor real-time price movements in charts available in 21 timeframes simultaneously. This feature allows traders to respond to quote changes instantly, and also store price history in a compact form.' },
        { icon: 'Assets/Images/exceptional-market.svg', title: 'Fundamental Analysis', description: 'Capture market opportunities with fundamental analysis tools on the MetaTrader 5, such as the built-in Economic Calendar. Keep abreast of the latest news events, expected market impacts and forecasts.' },
        { icon: 'Assets/Images/high-liquidity.svg', title: 'Indicators & Analytical Object Tools', description: 'Enhance your trading experience with 38 built-in indicators, 22 analytical tools and 46 graphical objects when you trade financial instruments via MT5.' },
    ];

    const StepsData = [
        { step: '01', title: 'Open', description: 'Open a FXOpulence account', icon: 'Assets/Images/open-account.svg' },
        { step: '02', title: 'Transfer', description: 'Transfer funds in your account', icon: 'Assets/Images/transfer.svg' },
        { step: '03', title: 'Trade', description: 'Start your trading journey', icon: 'Assets/Images/trade.svg' },
    ];

    const LoginStepData = [
        { step: '01', title: 'Run Terminal', description: 'Run the trading terminal your PC' },
        { step: '02', title: 'Access Login', description: 'On the terminal, click on ‘File’and then select ‘Login to Trade Account’' },
        { step: '03', title: 'Login', description: 'Next, enter your account number as your Login, your account trading password and the server your account was registered on.' },
        { step: '04', title: 'Start Trading', description: 'Once you have successfully logged in, you will hear a chime confirming your login' }
    ];

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <Layout>
            <Helmet>
                <title>Meta Trader 5</title>
            </Helmet>
            <div className='mt5-container'>
                <div className='download-mt5 pt-5'>
                    <div className='side-padding py-5'>
                        <div className='pb-5'>
                            <div className='title-blue mb-4'>
                                <p className='m-0'>Download MT5</p>
                            </div>
                            <p className='main-title'>MetaTrader 5 Trading Platform</p>
                            <p className='description mb-0 mt-4'>All platforms are available with a single FXOpulence account. Find a trading platform that fits your needs and experience level.</p>
                            <div className='pb-5'>
                                <div className='platform-card-wrapper mt-5 pb-5'>
                                    <div className='platform-card px-3 py-2 d-flex mr-5 my-3'>
                                        <img height={32} src='Assets/Images/window.svg' alt='icon' />
                                        <div className='ml-2'>
                                            <p className='m-0 text'>Download</p>
                                            <p className='m-0 plateform'>For Windows</p>
                                        </div>
                                    </div>
                                    <div className='platform-card px-3 py-2 d-flex mr-5 my-3'>
                                        <img height={32} src='Assets/Images/apple.svg' alt='icon' />
                                        <div className='ml-2'>
                                            <p className='m-0 text'>Download on the</p>
                                            <p className='m-0 plateform'>App Store</p>
                                        </div>
                                    </div>
                                    <div className='platform-card px-3 py-2 d-flex mr-5 my-3'>
                                        <img height={32} src='Assets/Images/android.svg' alt='icon' />
                                        <div className='ml-2'>
                                            <p className='m-0 text'>Get it on</p>
                                            <p className='m-0 plateform'>Google Play</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='trading-img-container'>
                        <div className='side-margin'>
                            <div className='row p-0 m-0'>
                                <div className='col-md-8 px-0'>
                                    <img className='ipad-img' src='Assets/Images/mt5-iPad-Pro.svg' alt='tablet-img' />
                                </div>
                                <div className='col-md-4 px-0'>
                                    <img className='iphone-img' src='Assets/Images/mt5-iPhone.svg' alt='tablet-img' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='benefits-container py-5'>
                    <div className='side-padding py-5'>
                        <div className='row p-0 m-0'>
                            <div className='col-md-5 pl-md-0 pr-md-5 my-md-4'>
                                <div className='title-blue mb-4'>
                                    <p className='m-0'>Benefits</p>
                                </div>
                                <p className='main-title'>MetaTrader 5 Main Features</p>
                            </div>
                            <div className='col-md-7 pr-md-0 pl-md-5 my-md-4'>
                                <img src='Assets/Images/metatrader-5-logo.svg' alt='mt5-logo' />
                                <p className='m-0 description mt-3'>MetaTrader 5, the fifth generation of the MetaTrader platforms, offers enhanced features compared to its predecessor. It swiftly rose to prominence among online foreign exchange traders and brokerages worldwide.</p>
                            </div>
                        </div>
                        <div className='benefit-card-wrapper pt-5'>
                            {
                                BenefitsData?.map((benefit, index) => (
                                    <div key={index} className={`my-4 `}>
                                        <div className='benefit-card py-4 pr-4'>
                                            <div className='icon-wrapper'>
                                                <img height={32} className='cursor-pointer' src={benefit?.icon} alt='icon' />
                                            </div>
                                            <div className='pl-4'>
                                                <p className='title my-3'>{benefit?.title}</p>
                                                <p className='description m-0'>{benefit?.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>

                <div className='get-started-container py-5'>
                    <div className='side-padding py-5'>
                        <div className='py-4 head'>
                            <div>
                                <div className='title-blue mb-4'>
                                    <p className='m-0'>Get Started</p>
                                </div>
                                <p className='main-title'>Start Trading On MT5.</p>
                            </div>
                            <div className='text-right w-30 mt-5'>
                                <div className='d-flex justify-content-end'>
                                    <button onClick={() => navigate('/openLiveAccount')} className='btn-blue-bg mb-md-3'>Start trading now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='position-relative'>
                        <div className='connection-line'></div>
                        <div className='side-padding'>
                            <div className='step-wrapper'>
                                {
                                    StepsData?.map((step, index) => (
                                        <div className='process-card p-3 my-3' key={index}>
                                            <div>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <img height={32} width={32} className='mr-4 cursor-pointer' src={step?.icon} alt='icon' />
                                                    <p className='step-no m-0'>{step?.step}</p>
                                                </div>
                                                <div className='mt-3'>
                                                    <p className='title mb-2 mt-2'>{step?.title}</p>
                                                    <p className='description m-0'>{step?.description}</p>
                                                </div>
                                            </div>
                                            <div className='connection-dot'></div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className='how-to-guide py-5'>
                    <div className='side-padding pt-md-5'>
                        <div className='py-4 head'>
                            <div>
                                <div className='title-blue mb-4'>
                                    <p className='m-0'>How to Guide</p>
                                </div>
                                <p className='main-title'>How To Login To MT5 Account For Windows, MacOS, And Linux:</p>
                            </div>
                            <div className='text-right w-30 mt-5'>
                                <div className='d-flex justify-content-end'>
                                    <button onClick={() => navigate('/openLiveAccount')} className='btn-blue-bg mb-md-3'>Start trading now</button>
                                </div>
                            </div>
                        </div>
                        <div className='login-step-wrapper'>
                            {
                                LoginStepData?.map((step, index) => (
                                    <div className='login-step-card my-5' key={index}>
                                        <div className='row p-0 m-0'>
                                            <div className='col-md-4 pl-4 pl-md-5 pt-4 pt-md-5'>
                                                <div className='d-flex'>
                                                    <p className='m-0 step-no px-3 py-1'>Step {step?.step}</p>
                                                </div>
                                                <p className='title'>{step?.title}</p>
                                                <p className='m-0 description'>{step?.description}</p>
                                            </div>
                                            <div className='col-md-8 p-0 text-right'>
                                                <img className='w-100 mt-5 mt-md-0' src='Assets/Images/login-step-ipad.svg' alt='img' />
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}