import React, { useEffect } from 'react';
import './Indices.scss';
import Layout from '../../../Components/Layout/Layout.js';
import { useNavigate } from 'react-router-dom';
import useForexData from '../../../Services/useForexData.js';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Helmet } from "react-helmet";


export default function Indices() {

    const navigate = useNavigate();
    const symbolsToMatch = ['AUS200', 'GER30', 'US30', 'EU50', 'FRA40', 'UK100', 'HKIND', 'US100', 'US500'];
    const forexData = useForexData(symbolsToMatch);

    const FeaturesData = [
        { icon: 'Assets/Images/how-it-works-2.svg', title: 'Wide Range', description: 'Options' },
        { icon: 'Assets/Images/wide-range.svg', title: '+154 Pair', description: 'of Coins' },
        { icon: 'Assets/Images/instruments.svg', title: 'Instruments', description: 'Features' },
        { icon: 'Assets/Images/how-it-works-4.svg', title: 'Synthetic Indices', description: 'Forex & crypto markets' }
    ];

    const BenefitsData = [
        { icon: 'Assets/Images/global-market.svg', title: 'Exposure to Global Markets', description: 'Access major global markets, like the Dow Jones, the S&P 500, the FTSE 100 and the Nikkei 225, among others.' },
        { icon: 'Assets/Images/diversification.svg', title: 'Diversification', description: 'Diversify your investment portfolio to gain exposure to a wide range of commodities or other financial assets.' },
        { icon: 'Assets/Images/favourable-swaps.svg', title: 'Leveraged Trading', description: 'Indices trading allows for leveraged trading, meaning you can trade with a larger position than your account balance.' },
        { icon: 'Assets/Images/high-liquidity.svg', title: 'Liquidity', description: 'Indices trading is a highly liquid market, meaning there are plenty of buyers and sellers at any given time.' },
    ];

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const statusIdentifier = (current, previous, digit) => {
        if (current?.toFixed(digit || 3) > previous?.toFixed(digit || 3)) {
            return '#28a745a1';
        } else {
            return '#ff00188c';
        }
    }

    return (
        <Layout>
            <Helmet>
                <title>Indices</title>
            </Helmet>
            <div className='instruments-indices-container'>
                <div className='indices-container py-5'>
                    <div className='side-padding py-5'>
                        <div className='py-4 head'>
                            <div>
                                <div className='title-white mb-4'>
                                    <p className='m-0'>Indices</p>
                                </div>
                                <p className='main-title'>Ride the market waves with FXOpulence's indices CFD trading</p>
                            </div>
                            <div className='text-right w-30 mt-5 mt-md-0'>
                                <p className='overview-head-text mb-4'>Trade global markets with high leverage and low costs.</p>
                                <div className='mt-5 pt-md-5 d-flex justify-content-end'>
                                    <button onClick={() => navigate('/openLiveAccount')} className='btn-white-bg mt-md-5'>Open Live Account</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='overview-container py-5'>
                    <div className='side-padding py-5'>
                        <div>
                            <div className='title-blue mb-4'>
                                <p className='m-0'>Overview</p>
                            </div>
                            <p className='main-title'>Trade popular <span>indices</span> and <span>seize</span> your next trading opportunity</p>
                            <p className='description mb-0 mt-4'>Maximize your trading potential with leverage and low spreads on major indices.</p>
                            <button className='mt-5 btn-blue-bg' onClick={() => navigate('/openLiveAccount')}>Start Trading Now</button>
                        </div>
                    </div>
                </div>
                <div className='start-now'>
                    <div className='feature-container-bg-color'>
                        <div className='side-padding py-4'>
                            <div className='feature-container'>
                                {
                                    FeaturesData?.map((feature, index) => (
                                        <div className='feature-card my-3' key={index}>
                                            <div className='d-flex'>
                                                <div>
                                                    <div className='icon-wrapper'>
                                                        <img height={28} className='cursor-pointer' src={feature?.icon} alt='icon' />
                                                    </div>
                                                </div>
                                                <p className='title m-0 ml-3'>{feature?.title}</p>
                                            </div>
                                            <p className='m-0 mt-1 description'>{feature?.description}</p>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className='side-padding py-md-5'>
                        <div className='row px-0 py-5 m-0'>
                            <div className='col-md-5 pl-md-0 pr-md-4 d-flex align-items-center'>
                                <div>
                                    <div className='title-blue mb-4'>
                                        <p className='m-0'>Start Now</p>
                                    </div>
                                    <p className='main-title'>Global spot CFD indices trading</p>
                                    <p className='description m-0'>Select amongst a variety of spot indices to trade.</p>
                                </div>
                            </div>
                            <div className='col-md-7 pr-md-0 pl-md-4 mt-4 mt-md-0 d-flex align-items-center w-100'>
                                <div className='symbol-table-container p-4'>
                                    <div className='table-responsive'>
                                        <table className='table borderless w-100 m-0'>
                                            <thead>
                                                <tr className='py-1'>
                                                    <th>Symbol</th>
                                                    <th>Bid Price</th>
                                                    <th>Ask Price</th>
                                                    <th>Spread</th>
                                                </tr>
                                            </thead>
                                            {
                                                forexData?.length > 0 ? (
                                                    <tbody>
                                                        {
                                                            forexData?.map((symbol, index) => (
                                                                <tr key={index}>
                                                                    <td><div className='py-1'>{symbol?.Symbol}</div></td>
                                                                    <td><div className='d-flex align-items-center justify-content-center' ><p className='m-0 rounded text-white px-2 py-1' style={{ backgroundColor: statusIdentifier(symbol?.bid, symbol?.prevBid, symbol?.digit || 3) }}>{(symbol?.bid)?.toFixed(symbol?.digit || 3)}</p></div></td>
                                                                    <td><div className='d-flex align-items-center justify-content-center' ><p className='m-0 rounded text-white px-2 py-1' style={{ backgroundColor: statusIdentifier(symbol?.ask, symbol?.prevAsk, symbol?.digit || 3) }}>{(symbol?.ask)?.toFixed(symbol?.digit || 3)}</p></div></td>
                                                                    <td><div className='py-1'>{Math.round(symbol?.spread)}</div></td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                ) : (
                                                    <tbody>
                                                        {
                                                            Array.from({ length: 5 }, (_, index) => (
                                                                <tr key={index}>
                                                                    <td><Skeleton height={32} /></td>
                                                                    <td><Skeleton height={32} /></td>
                                                                    <td><Skeleton height={32} /></td>
                                                                    <td><Skeleton height={32} /></td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                )
                                            }
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='benefits-container py-5'>
                        <div className='side-padding py-md-5'>
                            <div className='head'>
                                <div className='text-left'>
                                    <p className='head-text w-30 mb-4'>Access the best tools and resources throughout the trading.</p>
                                    <div className='mt-5 d-flex'>
                                        <button className='btn-blue-bg' onClick={() => navigate('/openLiveAccount')} >Open Live Account</button>
                                    </div>
                                </div>
                                <div className='mt-5 mt-md-0'>
                                    <div className='title-blue mb-4'>
                                        <p className='m-0'>Benefits</p>
                                    </div>
                                    <p className='main-title'>Tap into profits with popular indices</p>
                                </div>
                            </div>
                            <div className='row m-0 px-0 pt-5'>
                                {
                                    BenefitsData?.map((benefit, index) => (
                                        <div key={index} className={`col-md-6 my-4 ${index % 2 == 0 ? 'pl-md-0 pr-md-4' : 'pr-md-0 pl-md-4'}`}>
                                            <div className='benefit-card py-4 pr-4'>
                                                <div className='icon-wrapper'>
                                                    <img height={32} className='cursor-pointer' src={benefit?.icon} alt='icon' />
                                                </div>
                                                <div className='pl-4'>
                                                    <p className='title my-3'>{benefit?.title}</p>
                                                    <p className='description m-0'>{benefit?.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
