import React, { useEffect } from 'react';
import './PrivacyPolicy.scss';
import Layout from '../../Components/Layout/Layout.js';
import { Helmet } from "react-helmet";

export default function PrivacyPolicy() {

    const PrivacyPolicyData = {
        mainInformation: 'The General Information collected by FXOpulence is neither personal nor sensitive personal information. FXOpulence receives General Information through cookies and small text files sent from the web server to your computer. Cookies help FXOpulence get to know you better by providing operational data FXOpulence uses to aid your interaction on the Website by improving navigation and usability. You also have the option of disabling the cookies via your browser preferences. However, disabling cookies on the browser will result in some parts of the Website failing to function as effectively.',
        menus: [
            {
                title: 'Collecting personal data',
                subMenus: [
                    'By registering a Personal Area, you agree to have your personal data processed and specifically consent to the collection, systemization, aggregation, storage, revision (updating, changing), usage, anonymization, blocking deletion, and distribution (transfer) of said personal data to third parties in accordance with the conditions outlined in this Privacy Agreement.',
                    'We ask you for the data necessary for registering a Personal Area, providing functional service, and using this data if a dispute arises between you and FXOpulence. Providing this data is required for client identification and thus allows you to perform various operations in your Personal Area and trading accounts.',
                    'We may also collect data to improve our understanding of your needs and preferences by communicating through Live Chat, conducting brief surveys in your Personal Area on our official Website, and communicating with you over the phone. You provide this data to us voluntarily.',
                    'We use the data that you directly provide when communicating.',
                ]
            },
            {
                title: 'Use of personal data',
                subMenus: [
                    'We use your personal data to provide secure high-quality service.',
                    'The personal data you provide us with is used to verify contact information and identity. We also use personal data to register Personal Areas, open and configure trading accounts, and issue activation codes and passwords. By providing contact information, you help us improve our services and promptly communicate the availability of additional services, features, and promotions we may be offering.',
                ]
            },
            {
                title: 'Providing personal data to third parties',
                subMenus: [
                    'We do not provide your personal data to third parties except in the cases set forth by this Privacy Agreement.',
                    'We reserve the right to provide personal data to third parties if required by law or if we receive formal requests from law enforcement and other government agencies.',
                    'We may also provide your personal data to tax authorities or organizations that require the data to be provided to comply with laws against money laundering and financing terrorism or when necessary to protect our rights or property. We may pass your personal data on to third-party authentication service providers when you use our system to perform identity checks and document verification - we do this to comply with our legal obligations. When transferring your information to third parties, we maintain the confidentiality and security of the information being transferred.',
                    'In order to give you comprehensive, state-of-the-art, high-quality service, we may exchange your personal data with our partners. However, using your personal data is limited to sharing your account number with one of our partners at their request.',
                    'At your request, we can provide information about the services that our partners provide for us.',
                ]
            },
            {
                title: 'Limit on liability',
                subMenus: [
                    'This Privacy Agreement is valid only for our Website and does not cover other websites, including but not limited to websites you go to using links on our Website. We recommend that you review the privacy policy of each Website whose use involves transferring personal data.',
                ]
            },
            {
                title: 'Use of cookies',
                subMenus: [
                    'We use cookies to ensure our Website works effectively and supports your trading activities. Cookies are small text files sent from our web server to your computer. Our cookies do not contain any personal data, account numbers, or passwords.',
                    'We may enlist outside organizations to help us manage the Website and collect and analyze statistical data. These outside organizations may install and use their cookies on our behalf.',
                ]
            },
            {
                title: 'Security Systems',
                subMenus: [
                    'FXOpulence has taken all strict measures and precautions to keep personal and sensitive personal information safe and secure and to prevent unauthorized access to or misuse of the same.',
                    'FXOpulence will not be liable to any Client or Visitor (whether in contract, tort (including negligence) or otherwise) for any loss, damage (whether direct, indirect, consequential, or incidental), or harm caused to any Client or Visitor due to the unauthorized access or misuse of the personal information and sensitive personal information by any third party (beyond the reasonable control of FXOpulence).',
                    `FXOpulence's comprehensive and documented information security program contains managerial, technical, operational, and physical security control measures. The security practices are regularly reviewed to ensure they comply with the international standards on information technology-security techniques and approved best practices for data protection.However, the prevalence of identity theft has been in the news lately. We at FXOpulence would like to help you avoid falling victim to this. As such, we have included some helpful information concerning online identity theft and security reminders in the last section of this Policy. We suggest you carefully read the section in this Policy on "Online Identity Theft and Security Reminders."`,
                ]
            },
            {
                title: 'Commencement and amendment of the Privacy Agreement',
                subMenus: [
                    'By registering a Personal Area, you agree to and accept all of the provisions outlined in the Privacy Agreement. Communication between you and FXOpulence begins when we email you confirming your registration on our Website. We may also email you information about changes and new services or receive emails with your questions and suggestions. This information is not confidential.',
                    'By accepting the conditions of this Privacy Agreement, you agree to allow us to use the information we receive from you via email, including various ideas, methods, marketing designs, and know-how, without incurring any additional obligations. Your personally identifiable data is an exception.',
                    'We may review, store, and delete correspondence from you at our discretion.',
                    `We may analyze your internal messages to evaluate our services' quality.`,
                    'Occasionally, we may update the Privacy Agreement and immediately post the new version to our Website. Please periodically read this Agreement for the latest information concerning how your personal data is processed. If you have any questions or suggestions regarding this Agreement, please get in touch with us at support@fxopulence.com.',
                ]
            },
        ]
    };

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <Layout>
            <Helmet>
                <title>Privacy Policy</title>
            </Helmet>
            <div className='privacy-policy-container'>
                <div className='privacy-policy-header'>
                    <div className='side-padding py-0'>
                        <div className='row m-0 p-0'>
                            <div className='py-5 col-md-6 p-0'>
                                <div className='py-md-5'>
                                    <div className='title-blue mb-4 '>
                                        <p className='m-0'>FXOpulence</p>
                                    </div>
                                    <p className='main-text mb-4'>Privacy Policy</p>
                                </div>
                            </div>
                            <div className='col-md-6 p-0 d-flex justify-content-end'>
                                <div className='privacy-policy-bg'></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='detailed-info py-5'>
                    <div className='side-padding py-md-5'>
                        <p className='mb-4'>{PrivacyPolicyData?.mainInformation}</p>
                        <ol className='m-0 pl-4' type="1">
                            {
                                PrivacyPolicyData?.menus?.map((menu, index) => (
                                    <>
                                        <li key={index}>{menu?.title}</li>
                                        <ol className='m-0 pl-4' type="a">
                                            {
                                                menu?.subMenus?.map((subMenus, index) => (
                                                    <li key={index}>{subMenus}</li>
                                                ))
                                            }
                                        </ol>
                                    </>
                                ))
                            }
                        </ol>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
