import React, { useEffect } from 'react';
import './SyntheticIndices.scss';
import Layout from '../../../Components/Layout/Layout.js';
import { useNavigate } from 'react-router-dom';
import useForexData from '../../../Services/useForexData.js';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Helmet } from "react-helmet";

export default function SyntheticIndices() {

    const navigate = useNavigate();
    const symbolsToMatch = ['Currency100', 'Crypto500', 'Indices1000', 'EUR Basket', 'USD Basket', 'Currency50', 'Crypto250', 'Indices555', 'Metal Index', 'Speed Index', 'AUD_Basket', 'GBP_Basket'];
    const forexData = useForexData(symbolsToMatch);

    const StepsData = [
        { step: '01', title: 'Register', description: 'Open a demo account and practise with an unlimited amount of virtual funds.', icon: 'Assets/Images/open-account.svg' },
        { step: '02', title: 'Trade', description: 'Open a real account, make a deposit, and start trading synthetics and other markets.', icon: 'Assets/Images/trade.svg' },
        { step: '03', title: 'Withdraw', description: 'Conveniently withdraw your funds through any of our supported withdrawal methods.', icon: 'Assets/Images/extensive-funding.svg' },
    ]

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const statusIdentifier = (current, previous, digit) => {
        if (current?.toFixed(digit || 3) > previous?.toFixed(digit || 3)) {
            return 'green';
        } else {
            return 'red';
        }
    }

    return (
        <Layout>
            <Helmet>
                <title>Synthetic Indices</title>
            </Helmet>
            <div className='instruments-synthetic-container'>
                <div className='synthetic-container py-5'>
                    <div className='side-padding py-5'>
                        <div className='py-4 head'>
                            <div>
                                <div className='title-white mb-4'>
                                    <p className='m-0'>Derived</p>
                                </div>
                                <p className='main-title'>Harness The Power of FXOpulence Synthetics</p>
                            </div>
                            <div className='text-right w-30 mt-5 mt-md-0'>
                                <p className='overview-head-text mb-4'>Embrace the thrill of trading on asset prices derived from simulated markets. Dive into our 24/5 Synthetic Indices covering the forex, and crypto markets –specifically designed to help you lead confidently in any market environment.</p>
                                <div className='d-flex justify-content-end'>
                                    <button onClick={() => navigate('/openLiveAccount')} className='btn-white-bg mt-md-5'>Open Live Account</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='overview-container py-5'>
                    <div className='side-padding py-5'>
                        <div>
                            <div className='title-blue mb-4'>
                                <p className='m-0'>Overview</p>
                            </div>
                            <p className='main-title'>Access multiple <span>derived assets</span> on FXOpulence</p>
                            <p className='description mb-0 mt-4'>Our proprietary synthetic indices grounded in the FX, stock, and cryptocurrency markets offer an impeccable emulation of real-world market movements. Leveraging our advanced algorithms, these indices integrate high volatility assets, empowering you to trade freely around the clock, without being constrained by regular market hours or global events.</p>
                            <button className='mt-5 btn-blue-bg' onClick={() => navigate('/openLiveAccount')}>Start Trading Now</button>
                        </div>
                    </div>
                </div>

                <div className='indices-container py-5'>
                    <div className='side-padding py-4'>
                        <div className='row p-0 m-0'>
                            <div className='col-md-6 pl-md-0 pr-md-4 my-4'>
                                <div className='indices-card indices-1000-bg p-3 p-md-5'>
                                    <div>
                                        <div className='title mb-4'>
                                            <p className='m-0'>Indices</p>
                                        </div>
                                        <p className='main-text'>Indices1000</p>
                                        <div className='description'>Immerse yourself in the fascinating world of international finance through our Indices1000 index which covers the simulated price trends of the top 10 worldwide indices.</div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 pr-md-0 pl-md-4 my-4'>
                                <div className='indices-card crypto-500-bg p-3 p-md-5'>
                                    <div>
                                        <div className='title mb-4'>
                                            <p className='m-0'>Indices</p>
                                        </div>
                                        <p className='main-text'>Crypto500</p>
                                        <div className='description'>Our Crypto500 Index focuses on the top 5 influencers in the cryptocurrency space and tracks the major shifts and movements in their prices.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row p-0 m-0'>
                            <div className='col-md-6 pl-md-0 pr-md-4 my-4'>
                                <div className='indices-card currency-100-bg p-3 p-md-5 '>
                                    <div>
                                        <div className='title mb-4'>
                                            <p className='m-0'>Indices</p>
                                        </div>
                                        <p className='main-text'>Currency100</p>
                                        <div className='description'>Raise your trading prowess with our Currency100 index - a comprehensive simulated index showcasing the price swings of the top 10 most traded currency pairs.</div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 pr-md-0 pl-md-4 my-4'>
                                <div className='indices-card euro-basket-bg p-3 p-md-5'>
                                    <div>
                                        <div className='title mb-4'>
                                            <p className='m-0'>Indices</p>
                                        </div>
                                        <p className='main-text'>EURO Basket</p>
                                        <div className='description'>The deposit system in FXOpulence is extremely fast, wherein traders will use any crypto wallet.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row p-0 m-0'>
                            <div className='col-12 px-md-0 my-4'>
                                <div className='indices-card usd-basket-bg p-3 p-md-5'>
                                    <div>
                                        <div className='title mb-4'>
                                            <p className='m-0'>Indices</p>
                                        </div>
                                        <p className='main-text'>USD Basket</p>
                                        <div className='description'>With our USD_Basket basket, leverage the strength of the US Dollar against a balanced assembly of AUD, EUR, GBP, JPY, and CAD for a diversified market experience.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='start-now'>
                    <div className='side-padding py-md-5'>
                        <div className='row px-0 py-5 m-0'>
                            <div className='col-md-5 pl-md-0 pr-md-4 d-flex align-items-center'>
                                <div>
                                    <div className='title-blue mb-4'>
                                        <p className='m-0'>Start Now</p>
                                    </div>
                                    <p className='main-title'>Seamlessly Trade Synthetics Available With Us</p>
                                    <p className='description m-0'>Trade the most popular currencies on our platform</p>
                                </div>
                            </div>
                            <div className='col-md-7 pr-md-0 pl-md-4 mt-4 mt-md-0 d-flex align-items-center w-100'>
                                <div className='symbol-table-container p-4'>
                                    <div className='table-responsive'>
                                        <table className='table borderless w-100 m-0'>
                                            <thead className='border-bottom'>
                                                <tr className='py-1'>
                                                    <th>Symbol</th>
                                                    <th>Bid Price</th>
                                                    <th>Ask Price</th>
                                                    <th>Spread</th>
                                                </tr>
                                            </thead>
                                            {
                                                forexData?.length > 0 ? (
                                                    <tbody>
                                                        {
                                                            forexData?.map((symbol, index) => (
                                                                <tr key={index}>
                                                                    <td><div className='py-1'>{symbol?.Symbol}</div></td>
                                                                    <td><div className='d-flex align-items-center justify-content-center' ><p className='m-0 rounded text-white px-2 py-1' style={{ backgroundColor: statusIdentifier(symbol?.bid, symbol?.prevBid, symbol?.digit || 3) }}>{(symbol?.bid)?.toFixed(symbol?.digit || 3)}</p></div></td>
                                                                    <td><div className='d-flex align-items-center justify-content-center' ><p className='m-0 rounded text-white px-2 py-1' style={{ backgroundColor: statusIdentifier(symbol?.ask, symbol?.prevAsk, symbol?.digit || 3) }}>{(symbol?.ask)?.toFixed(symbol?.digit || 3)}</p></div></td>
                                                                    <td><div className='py-1'>{Math.round(symbol?.spread)}</div></td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                ) : (
                                                    <tbody>
                                                        {
                                                            Array.from({ length: 6 }, (_, index) => (
                                                                <tr key={index}>
                                                                    <td><Skeleton height={32} /></td>
                                                                    <td><Skeleton height={32} /></td>
                                                                    <td><Skeleton height={32} /></td>
                                                                    <td><Skeleton height={32} /></td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                )
                                            }
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='get-started py-5'>
                    <div className='side-padding py-md-5'>
                        <div className='py-4 head'>
                            <div>
                                <div className='title-blue mb-4'>
                                    <p className='m-0'>Get Started</p>
                                </div>
                                <p className='main-title'>Start Trading Synthetics On FXOpulence In 3 Simple Steps</p>
                            </div>
                            <div className='text-right w-30 mt-5'>
                                <div className='d-flex justify-content-end'>
                                    <button className='btn-blue-bg mb-md-3' onClick={() => navigate('/openLiveAccount')}>Start trading now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='position-relative'>
                        <div className='connection-line'></div>
                        <div className='side-padding'>
                            <div className='step-wrapper'>
                                {
                                    StepsData?.map((step, index) => (
                                        <div className='process-card p-3 my-3' key={index}>
                                            <div>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <img height={32} width={32} className='mr-4 cursor-pointer' src={step?.icon} alt='icon' />
                                                    <p className='step-no m-0'>{step?.step}</p>
                                                </div>
                                                <div className='mt-3'>
                                                    <p className='title mb-2 mt-2'>{step?.title}</p>
                                                    <p className='description m-0'>{step?.description}</p>
                                                </div>
                                            </div>
                                            <div className='connection-dot'></div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
