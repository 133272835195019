import React from 'react';
import './Payments.scss';
import Layout from '../../Components/Layout/Layout.js';
import { useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";

export default function Payments() {

    const navigate = useNavigate();
    const PaymentMethodsData = [
        { icon: 'Assets/Images/ethereum-icon.svg', whiteBg: false, name: 'ETH', deposit: '$50 USD', depositSpeed: 'Instant', withdrawal: '$50 USD', withdrawalSpeed: 'Network Confirmation' },
        { icon: 'Assets/Images/bep-20-coin.svg', whiteBg: false, name: 'BEP-20', deposit: '$50 USD', depositSpeed: 'Instant', withdrawal: '$50 USD', withdrawalSpeed: 'Network Confirmation' },
        { icon: 'Assets/Images/tron-icon.svg', whiteBg: true, name: 'TRC-20', deposit: '$50 USD', depositSpeed: 'Instant', withdrawal: '$50 USD', withdrawalSpeed: 'Network Confirmation' },
        { icon: 'Assets/Images/white-bit-coin.svg', whiteBg: false, name: 'BTC', deposit: '$50 USD', depositSpeed: 'Instant', withdrawal: '$50 USD', withdrawalSpeed: 'Network Confirmation' },
    ];

    return (
        <Layout>
            <Helmet>
                <title>Payments</title>
            </Helmet>
            <div className='payments-container'>
                <div className='side-padding'>
                    <div className='row py-5'>
                        <div className='offset-md-3 col-md-6'>
                            <div className='py-md-4 text-left'>
                                <div className='title-blue mb-4 mt-5'>
                                    <p className='m-0'>First forex broker with decentralized wallet</p>
                                </div>
                                <p className='main-text mb-4'>No Deposit Fees & Fast Withdrawal</p>
                                <p className='m-0 short-description'>Experience seamless transactions - Zero deposit charges & immediate payouts with FXOpulence</p>
                                <div className='mt-5'>
                                    <button className='btn-blue-bg py-2' onClick={() => navigate('/openLiveAccount')}>Get Started</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='payments-deposit-withdrawal-bg'></div>
                <div className='side-padding pb-5'>
                    <div className='row px-0 mx-0 py-5 my-5'>
                        <div className='col-md-9 pl-md-0'>
                            <div className='deposit-withdrawal-card globe_bottom_right p-md-5 p-4'>
                                <div className='text-left'>
                                    <div className='title-blue mb-4'>
                                        <p className='m-0'>Payments</p>
                                    </div>
                                    <p className='main-text mb-4'>Deposit</p>
                                    <p className='m-0 short-description'>The deposit system in FXOpulence is extremely fast, wherein traders will use any crypto wallet to fund the FXO trading account at any time without any approval from the broker.</p>
                                    <div className='mt-5'>
                                        <button className='btn-blue-bg' onClick={() => navigate('/openLiveAccount')}>Start Deposit</button>
                                    </div>
                                </div>
                                <div className='position-relative'>
                                    <div className='connection-line'></div>
                                    <div className='deposit-withdrawal-process-container mt-5 mb-3'>
                                        <div className='process-step-card p-3 my-2'>
                                            <div className='d-flex'>
                                                <img height={45} className='main-img' src='Assets/Images/Profile.svg' alt='icon' />
                                                <div className='ml-3'>
                                                    <p className='m-0 title'>Deposit Request</p>
                                                    <div className='d-flex mt-2'>
                                                        <img src='Assets/Images/bitcoin-orange.svg' alt='icon' />
                                                        <p className='value m-0 ml-2'>$1500</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='process-step-card p-3 my-2'>
                                            <div className='ml-3'>
                                                <p className='m-0 title'>Automatic Response <span>●</span></p>
                                                <div className='d-flex mt-2'>
                                                    <img src='Assets/Images/timer-flash-line.svg' alt='icon' />
                                                    <p className='value m-0 ml-2'>0.05 ms</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='process-step-card p-3 my-2'>
                                            <div className='ml-3'>
                                                <p className='m-0 title'>Successful Deposit</p>
                                                <div className='d-flex mt-2'>
                                                    <img src='Assets/Images/checkbox-circle-line.svg' alt='icon' />
                                                    <p className='value m-0 ml-2'>$1500</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='process-step-card p-3 my-2'>
                                            <div className='ml-3 text-left'>
                                                <p className='m-0 title'>Trading Account</p>
                                                <img className='mt-2' src='Assets/Logo/Logo.svg' alt='logo' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row px-0 mx-0 pb-5'>
                        <div className='offset-md-3 col-md-9 pr-md-0'>
                            <div className='deposit-withdrawal-card globe_top_right p-md-5 p-4'>
                                <div className='text-left'>
                                    <div className='title-blue mb-4'>
                                        <p className='m-0'>Payments</p>
                                    </div>
                                    <p className='main-text mb-4'>Withdrawal</p>
                                    <p className='m-0 short-description'>With FXOpulence's decentralized wallet system, traders can withdraw their money quickly anywhere around the world, at any time, and access their funds via digital wallets without any manual approval by the broker.</p>
                                    <div className='mt-5'>
                                        <button className='btn-blue-bg' onClick={() => navigate('/openLiveAccount')}>Start trading 🡥</button>
                                    </div>
                                </div>
                                <div className='position-relative'>
                                    <div className='connection-line'></div>
                                    <div className='deposit-withdrawal-process-container mt-5 mb-3'>
                                        <div className='process-step-card p-3 my-2'>
                                            <div className='d-flex'>
                                                <img height={45} className='main-img' src='Assets/Images/Profile.svg' alt='icon' />
                                                <div className='ml-3'>
                                                    <p className='m-0 title'>Withdraw Request</p>
                                                    <div className='d-flex mt-2'>
                                                        <img src='Assets/Images/bitcoin-orange.svg' alt='icon' />
                                                        <p className='value m-0 ml-2'>$1500</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='process-step-card p-3 my-2'>
                                            <div className='ml-3'>
                                                <p className='m-0 title'>Automatic Response <span>●</span></p>
                                                <div className='d-flex mt-2'>
                                                    <img src='Assets/Images/timer-flash-line.svg' alt='icon' />
                                                    <p className='value m-0 ml-2'>0.05 ms</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='process-step-card p-3 my-2'>
                                            <div className='d-flex'>
                                                <img height={45} className='main-img' src='Assets/Images/bank-circle-line.svg' alt='icon' />
                                                <div className='ml-3'>
                                                    <p className='m-0 title'>Successful Deposit</p>
                                                    <div className='d-flex mt-2'>
                                                        <img src='Assets/Images/checkbox-circle-line.svg' alt='icon' />
                                                        <p className='value m-0 ml-2'>$1500</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='how-it-works'>
                    <div className='side-padding'>
                        <div className='py-5'>
                            <div className='row m-0 p-0'>
                                <div className='col-md-6 p-0 m-0'>
                                    <div className='title-blue mb-4 mt-5'>
                                        <p className='m-0'>How it works</p>
                                    </div>
                                    <p className='main-title mb-4'>How Our Deposit And Withdrawal Works</p>
                                </div>
                            </div>
                            <div className='row p-0 m-0 pt-4'>
                                <div className='col-md-6 pl-md-0 pr-md-4'>
                                    <div className='how-it-works-card pb-3 pt-4 pr-3 my-4'>
                                        <div>
                                            <div className='icon-wrapper'>
                                                <img height={28} className='cursor-pointer' src='Assets/Images/how-it-works-1.svg' alt='icon' />
                                            </div>
                                        </div>
                                        <p className='description m-0 ml-3'>FXOpulence empowers clients in the forex industry to effectively manage and oversee their funds, addressing their most pressing challenges.</p>
                                    </div>
                                </div>
                                <div className='col-md-6 pr-md-0 pl-md-4'>
                                    <div className='how-it-works-card pb-3 pt-4 pr-3 my-4'>
                                        <div>
                                            <div className='icon-wrapper'>
                                                <img height={28} className='cursor-pointer' src='Assets/Images/how-it-works-2.svg' alt='icon' />
                                            </div>
                                        </div>
                                        <p className='description m-0 ml-3'>For this cause, the broker leverages cutting-edge Blockchain technology to offer clients a decentralized wallet, allowing them to deposit, withdraw, and transfer funds anytime they wish. </p>
                                    </div>
                                </div>
                            </div>
                            <div className='row p-0 m-0'>
                                <div className='col-md-7 pl-md-0 pr-md-4'>
                                    <div className='how-it-works-card pb-3 pt-4 pr-3 my-4'>
                                        <div>
                                            <div className='icon-wrapper'>
                                                <img height={28} className='cursor-pointer' src='Assets/Images/how-it-works-3.svg' alt='icon' />
                                            </div>
                                        </div>
                                        <p className='description m-0 ml-3'>Opening a live account automatically activates a decentralized wallet for the client. Upon funding their trading account, money is placed in this wallet, which they can then transfer to their Mt5 trading account.</p>
                                    </div>
                                </div>
                                <div className='col-md-5 pr-md-0 pl-md-4'>
                                    <div className='how-it-works-card pb-3 pt-4 pr-3 my-4'>
                                        <div>
                                            <div className='icon-wrapper'>
                                                <img height={28} className='cursor-pointer' src='Assets/Images/how-it-works-4.svg' alt='icon' />
                                            </div>
                                        </div>
                                        <p className='description m-0 ml-3'>For withdrawals, clients can move funds to their decentralized wallet and then withdraw them to any exchange.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='payment-methods pt-5'>
                    <div className='side-padding py-5'>
                        <div className='head'>
                            <div>
                                <div className='title-blue mb-4'>
                                    <p className='m-0'>Payment Methods</p>
                                </div>
                                <p className='main-title'>Our Payment Methods</p>
                            </div>
                            <div className='text-right w-30 mt-5 mt-md-0'>
                                <p className='head-text mb-4'>Get started with our <span>super fast trading</span> account.</p>
                                <div className='mt-5 d-flex justify-content-end'>
                                    <button className='btn-blue-bg' onClick={() => navigate('/openLiveAccount')}>Get Started</button>
                                    <button className='btn-blue-border ml-3' onClick={() => navigate('/openDemoAccount')}>Open Demo Account</button>
                                </div>
                            </div>
                        </div>
                        <div className='row px-0 m-0 py-5'>
                            {
                                PaymentMethodsData?.map((method, index) => (
                                    <div key={index} className={`col-md-6 my-4 ${index % 2 == 0 ? 'pl-md-0 pr-md-4' : 'pr-md-0 pl-md-4'}`}>
                                        <div className='pyment-method-card p-4 p-md-5'>
                                            <div className='d-flex align-items-center'>
                                                <img src={method?.icon} alt='icon' className={`payment-method-coin-image ${method?.whiteBg ? 'payment-method-coin-image-bg-white' : ''}`} />
                                                <p className='payment-title m-0 ml-2'>{method?.name}</p>
                                            </div>
                                            <div className='inner-card mt-5 p-3 p-md-4'>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <div className='d-flex align-items-center'>
                                                        <img src='Assets/Images/wallet-blue-bg.svg' alt='icon' />
                                                        <p className='m-0 title ml-2'>Deposit <span>(Min)</span></p>
                                                    </div>
                                                    <p className='m-0 value'>{method?.deposit}</p>
                                                </div>
                                                <div className='d-flex justify-content-between align-items-center mt-4'>
                                                    <div className='d-flex align-items-center'>
                                                        <img src='Assets/Images/rocket.svg' alt='icon' />
                                                        <p className='m-0 title ml-2'>Speed</p>
                                                    </div>
                                                    <p className='m-0 value'>{method?.depositSpeed}</p>
                                                </div>
                                                <div className='devider my-3 w-75 mx-auto'></div>
                                                <div className='d-flex justify-content-between align-items-center mt-4'>
                                                    <div className='d-flex align-items-center'>
                                                        <img src='Assets/Images/bank-green-bg.svg' alt='icon' />
                                                        <p className='m-0 title ml-2'>Withdrawal <span>(Min)</span></p>
                                                    </div>
                                                    <p className='m-0 value'>{method?.withdrawal}</p>
                                                </div>
                                                <div className='d-flex justify-content-between align-items-center mt-4'>
                                                    <div className='d-flex align-items-center'>
                                                        <img src='Assets/Images/rocket.svg' alt='icon' />
                                                        <p className='m-0 title ml-2'>Speed</p>
                                                    </div>
                                                    <p className='m-0 value'>{method?.withdrawalSpeed}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}